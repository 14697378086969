/* eslint-disable react/display-name */
import { BaseButtonProps } from '@utils/types/baseComponents';
import { Facebook, Google } from 'assets/icons';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

type Props = {
  socialType: 'facebook' | 'google';
} & BaseButtonProps;

export function SocialButton(props: Omit<Props, 'label'>) {
  const { onClick: onClick, socialType } = props;
  const [label, setLabel] = useState('');
  const [Icon, setIcon] = useState(() => (props: any) => <></>);

  useEffect(() => {
    switch (socialType) {
      case 'facebook':
        setIcon(() => (props: any) => <Facebook {...props} />);
        setLabel('Continue With Facebook');
        break;
      case 'google':
        setIcon(() => (props: any) => <Google {...props} />);
        setLabel('Continue With Google');
        break;
      default:
        setLabel('Continue');
        break;
    }
  }, [socialType]);

  return (
    <button
      className={clsx([styles.button, styles[socialType]])}
      onClick={onClick}>
      <div className={clsx(styles.icon)}>
        <Icon fill={'#FFF'} height={24} width={24} />
      </div>
      {label}
    </button>
  );
}
