import { Background } from '@atoms/background';
import { LoginInFormTypes } from '@molecules/loginFormFields';
import { LoginForm } from '@organisms/loginForm';
import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';
import { Logo } from "@atoms/logo";

type Props = {
  onSubmit: (values: LoginInFormTypes) => void;
  onSignUpClick: () => void;
  onGoogleLoginClick: () => void;
  onFacebookLoginClick: () => void;
  onPasswordForgot: () => void;
  errorMessage?: string;
};

/** Login component */
export function Login(props: Props) {
  return (
    <Background withImage containerStyle={clsx(styles.container)}>
      <Logo containerStyle={clsx(styles.logoContainer)} />
      <LoginForm containerStyle={clsx([styles.loginContainer])} {...props} />
    </Background>
  );
}
