import { OnboardingContainer } from '@atoms/onboardingContainer';
import SignUpLink from '@atoms/signUpLink';
import { SocialButton } from '@atoms/socialButton';
import { LoginFormFields, LoginInFormTypes } from '@molecules/loginFormFields';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { SegmentEventNamesEnum, SegmentEventPagesEnum, useAnalytics } from '@utils/hooks/useAnalytics';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  onSubmit: (values: LoginInFormTypes) => void;
  onFacebookLoginClick: () => void;
  onGoogleLoginClick: () => void;
  onSignUpClick: () => void;
  onPasswordForgot: () => void;
  errorMessage?: string;
} & BaseComponentProps;

LoginForm.defaultProps = {
  onFacebookLoginClick: () => {},
  onGoogleLoginClick: () => {},
  onSignUpClick: () => {},
  onPasswordForgot: () => {},
};

/** Login form */
export function LoginForm(props: Props) {
  const {
    containerStyle,
    onSubmit,
    onSignUpClick,
    onGoogleLoginClick,
    onFacebookLoginClick,
    onPasswordForgot,
    errorMessage,
  } = props;
  const { trackEvent } = useAnalytics();

  const router = useRouter();

  const goBack = () => router.back();

  const onFacebookClick = () => {
    trackEvent(SegmentEventNamesEnum.CONTINUE_WITH_FACEBOOK_CLICK, {
      PageName: SegmentEventPagesEnum.HOME_PAGE
    });
    onFacebookLoginClick();
  };

  const onGoogleClick = () => {
    trackEvent(SegmentEventNamesEnum.CONTINUE_WITH_GOOGLE_CLICK, {
      PageName: SegmentEventPagesEnum.HOME_PAGE
    });
    onGoogleLoginClick();
  };

  const onLogin = (data: { email: string; password: string; }) => {
    trackEvent(SegmentEventNamesEnum.LOGIN_CLICK, {
      PageName: SegmentEventPagesEnum.HOME_PAGE
    });
    onSubmit(data);
  };

  const onSignUp = () => {
    trackEvent(SegmentEventNamesEnum.SIGNUP_BUTTON_CLICK, {
      PageName: SegmentEventPagesEnum.HOME_PAGE
    });
    onSignUpClick();
  };

  return (
    <OnboardingContainer containerStyle={containerStyle} onCloseButtonClick={goBack}>
      <div className={clsx([styles.formContainer])}>
        <span className={clsx([styles.title])}>Log In</span>
        <span className={clsx([styles.subtitle])}>Welcome back</span>
        {errorMessage && <span className={styles.error}>*{errorMessage}</span>}
        <SocialButton socialType='facebook' onClick={onFacebookClick} />
        <SocialButton socialType='google' onClick={onGoogleClick} />
        <span className={clsx([styles.word])}>or</span>
        <LoginFormFields
          onSubmit={onLogin}
          onPasswordForgot={onPasswordForgot}
        />
        <SignUpLink onSignUpClick={onSignUp} />
      </div>
    </OnboardingContainer>
  );
}
