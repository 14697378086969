import { BaseInputProps } from '@utils/types/baseComponents';
import { TextInput } from '@atoms/textInput';
import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  /** Forgot password callback */
  onForgotPasswordClick: () => void;
} & BaseInputProps;

/** Text input */
export function PasswordInput(props: Props) {
  const {
    label,
    onPressEnter,
    onTextChange,
    onForgotPasswordClick,
    placeholder,
    error,
    touched,
    required,
    name,
  } = props;

  const onChange = (value: string) => {
    onTextChange && onTextChange(value);
  };

  return (
    <div className={clsx([styles.container])}>
      <div className={clsx([styles.inputHeader])}>
        <span>{label}</span>
        <span
          onClick={onForgotPasswordClick}
          className={clsx([styles.forgotPassword])}>
          Forgot your password?
        </span>
      </div>
      <TextInput
        name={name}
        required={required}
        containerStyle={clsx(styles.inputContainer)}
        type={'password'}
        placeholder={placeholder}
        onTextChange={onChange}
        error={error}
        touched={touched}
        {...(!!onPressEnter && { onPressEnter })}
      />
    </div>
  );
}
