import React from 'react';
import clsx from 'clsx';
import { BaseComponentProps } from '@utils/types/baseComponents';
import styles from './styles.module.scss';

type Props = {
  onSignUpClick: () => void;
} & BaseComponentProps;

function SignUpLink(props: Props) {
  const {
    containerStyle,
    onSignUpClick,
  } = props;

  return (
    <div className={clsx([styles.signUpLink, containerStyle])}>
      Don&apos;t have an account? <a onClick={onSignUpClick}>Sign Up</a>
    </div>
  );
}

export default SignUpLink;
