import { BaseComponentProps } from '@utils/types/baseComponents';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { CloseButton } from '@atoms/closeButton';
import styles from './styles.module.scss';

type Props = {

  /** if the onCloseButtonClick prop is passed then the container
   * will have the X button for some action like a redirect from the page
   */
  onCloseButtonClick?: () => void;
} & BaseComponentProps;

OnboardingContainer.defaultProps = {
  onCloseButtonClick: undefined,
};

/** Responsive onboarding container */
export function OnboardingContainer(props: PropsWithChildren<Props>) {
  const { children, onCloseButtonClick, containerStyle } = props;
  const isMobile = useIsMobile();

  return (
    <div className={clsx([styles.container, containerStyle])}>
      {!!onCloseButtonClick && !isMobile && (
        <CloseButton onClick={onCloseButtonClick} containerStyle={styles.closeButton} />
      )}
      {children}
    </div>
  );
}
