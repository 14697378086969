import { facebookSignIn } from '@api/auth/facebookSignIn';
import { googleSignIn } from '@api/auth/googleSignIn';
import { LoginInFormTypes } from '@molecules/loginFormFields';
import { Login } from '@organisms/login';
import { useSession } from '@utils/context/session';
import { withSession } from '@utils/hocs';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useState } from 'react';

const LoginPage: NextPage = () => {
  const router = useRouter();
  const { login } = useSession();
  const [errorMessage, setErrorMessage] = useState<string>();

  const onSignUpClick = () => router.push('sign-up');
  const onPasswordForgot = () => router.replace('forgot-your-password');

  const onSubmit = async (values: LoginInFormTypes) => {
    try {
      await login(values.email, values.password);
      router.replace('/home');
    } catch (error: any) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <div>
      <Login
        onFacebookLoginClick={() => facebookSignIn()}
        onGoogleLoginClick={() => googleSignIn()}
        onSignUpClick={onSignUpClick}
        onPasswordForgot={onPasswordForgot}
        onSubmit={onSubmit}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default withSession(LoginPage);
