import React from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import { BaseComponentProps } from '@utils/types/baseComponents';
import Yup from '@utils/yup';
import { Button } from '@atoms/button';
import { TextInput } from '@atoms/textInput';
import { PasswordInput } from '@atoms/passwordInput';
import styles from './styles.module.scss';

export type LoginInFormTypes = typeof loginInitialValues;

type Props = {
  onSubmit: (values: LoginInFormTypes) => void;
  onPasswordForgot: () => void;
} & BaseComponentProps;

const loginInitialValues = {
  email: '',
  password: '',
};

const validationsSchema = Yup.object().shape({
  email: Yup.string(),
  password: Yup.string(),
});

/** Login form fields */
export function LoginFormFields(props: Props) {
  const { onSubmit, onPasswordForgot } = props;

  return (
    <Formik
      initialValues={loginInitialValues}
      onSubmit={({ email, password }) => onSubmit({ email, password })}
      validationSchema={validationsSchema}>
      {({ errors, touched, setFieldValue }) => (
        <Form>
          <TextInput
            name='email'
            required
            touched={touched.email}
            label='Email'
            type={'email'}
            onTextChange={(value) => setFieldValue('email', value)}
            error={errors.email}
          />
          <PasswordInput
            required
            label='Password'
            name='email'
            onForgotPasswordClick={onPasswordForgot}
            onTextChange={(value) => setFieldValue('password', value)}
            error={errors.password}
            touched={touched.password}
          />
          <Button
            type='submit'
            label='Log in'
            containerStyle={clsx(styles.loginContainer)}
          />
        </Form>
      )}
    </Formik>
  );
}
